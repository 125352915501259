// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

//EN: Try not to use this file for global styling
//Use the GlobalStyles component instead as this allows us to create global styles from the theme
body {
    overflow: auto !important;
}
html,
body {
}
body {
    overflow: auto !important;
}
#root {
    height: 100vh;
}

.error-login .ms-MessageBar {
    background-color: rgb(220, 53, 69);
    color: rgb(255, 255, 255);
    .ms-MessageBar-icon {
        i {
            color: #dc3545;
            background: #fff;
            font-weight: bold;
            height: 15px;
            width: 15px;
            border-radius: 50%;
        }
    }
    .ms-MessageBar-innerText {
        font-family: Poppins, sans-serif;
    }
}

span.rightIcon {
    display: inline-block;
    float: left;
    position: relative;
    top: -43px;
    left: 100%;
}
body > iframe {
    height: 0 !important;
    width: 0 !important;
}

.big-rating-round {
    width: 22px;
    height: 22px;
    background-color: #e1e1e1;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 4px;
}

.rating-round {
    width: 15px;
    height: 15px;
    background-color: #e1e1e1;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 4px;
}

.big-rating-round.active,
.rating-round.active {
    background-color: #ff4b22;
}

.big-rating-round.purple.active,
.rating-round.purple.active {
    background-color: #c15db0;
}

.contactView-block {
    padding: 30px;
    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        .ms-SearchBox {
            max-width: 32%;
            width: 100%;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #d4d4d4;
            height: 40px;
            margin: 0;
            i {
                color: #ff4b22;
                font-weight: 900;
            }
        }
        button {
            span {
                font-size: 12px;
            }
        }
    }
    .ms-DetailsList-contentWrapper {
        .ms-List-page span {
            font-size: 12px;
        }
        .ms-DetailsRow-cel img {
            height: 20px;
            width: 20px;
        }
        .ms-DetailsRow-cel {
            text-align: center;
        }
        .ms-DetailsRow-fields .ms-DetailsRow-cell:first-child {
            text-align: left;
        }
        .ms-DetailsRow-fields .ms-DetailsRow-cell:last-child {
            text-align: right;
            justify-content: end;
        }
    }
}

.border_block {
    border-right: 1px solid #e1e1e1;
}
.customicon {
    width: 14px;
}
.editicon svg {
    margin-right: 8px;
}
.editicon path {
    fill: #fff;
}

// .ms-List-page .ms-List-cell:nth-child(1) .border_block:nth-child(1) .root-249  {
//     color: #cc0000;
// }
// .ms-List-page .ms-List-cell .border_block:nth-child(1) .root-249  {
//     color: #cc0000;
// }
// .ms-List-page .ms-List-cell:nth-child(0) .root-249  {
//     color: #c871ba;
// }

// .ms-List-page .ms-List-cell .root-249 {
//     color: #333;
// }
// .ms-List-page .ms-List-cell:nth-child(2) .root-249 {
//     color: #ff4b22;
// }

.textCenter {
    text-align: center !important;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .contactView-block-head {
        display: block;
        .ms-SearchBox {
            max-width: 100%;
        }
        .ms-Button {
            width: 100%;
            margin-top: 15px;
            height: 40px;
        }
    }
}

.sortIcon-192 {
    background-image: url(./Assets/sort.svg);
    background-repeat: no-repeat;
    background-position: left;
    width: 10px;
    font-size: 0;
    position: absolute;
    left: 0px;
    top: 0;
}

.address-checkbox {
    .ms-Label {
        font-weight: 600;
    }
    .ms-Checkbox-checkmark {
        color: #ff4b22 !important;
    }
}
.noted-inq-block {
    border: 1px dashed #ff4b22;
    padding: 20px;
    .css-mhde0k-InternalBox-Container {
        border: 0;
    }
}
.dropdownAddress input {
    width: 100%;
}

.ms-DetailsHeader-cellSizer::after {
    opacity: 1 !important;
    background-color: #fff !important;
}
.ms-DetailsHeader-cellTitle {
    border-right: 1px solid rgb(225, 225, 225);
    height: 100%;
}

.clientDetailsList {
    display: grid !important;
    grid-template-columns: 3fr 2fr 3fr 4fr;
    & > div {
        padding: 6px 12px;
    }
}

.tox.tox-tinymce.tox-fullscreen {
    // This is the height of the application bar.
    padding-top: 70px;
    // This is the height of the footer.
    padding-bottom: 60px;
}

@font-face {
    font-family: "HeadingNot55Medium";
    src: local("HeadingNot55Medium"), url("./Assets/Fonts/Heading-Now-55-Medium.ttf") format("truetype");
}
